import {
  Box,Text,Stack, HStack,Flex,Image,Checkbox,Divider,Button,Spinner,IconButton,Tooltip, calc,extendTheme,ChakraProvider,
} from "@chakra-ui/react"
import React,{useState,useEffect,useContext} from "react"
import {TbRefresh} from 'react-icons/tb'
import { RxExternalLink } from "react-icons/rx"
import { HiOutlineTrash } from "react-icons/hi"
import back from '../../../assets/back.png'
import notFound from '../../../assets/notfound.png'
import AppContext from 'AppContext'
import { WorkspaceContext } from '../../commonComponents/Layout/Layout'
import { useServicesContext } from "services/apiServices"
import { useSharepointContext } from "./context/SharepointContext"

const commonProps = {borderRadius:'2px', px:'5px', py:'2px',fontSize:'11px'}
const orangeBadgeProps={...commonProps, color:'#E79B04', bg:'#F7E9CE', border:'1px solid #E79B04'}
const greenBadgeProps={...commonProps, color:'#2AC769', bg:'#DFF7E9', border:'1px solid #2AC769'}

const SharepointPreviewPage = () => {
  const {createOrUpdateAsync} = useServicesContext()
  const { globalSiteState,refreshSiteList, closeDrawer, setDrawerLoading, onCreatePublishSuccess, onCreatePublishFailure,onCreatePublishSuccessLater } = useSharepointContext()
  const[checked,setChecked]=useState([])
  const[recrawledSites,setRecrawledSites]=useState([])
  const[recrawlingSite,setReCrawlingSite]=useState('')
  const[deletingSiteId,setDeletingSiteId]=useState('')
  const[records,setRecords]=useState([])
  const[uncrawled,setUncrawled]=useState([])
  const[isLoading,setIsLoading]=useState(true)

  const {identity} = useContext(AppContext)
  const tenantId = identity?.profile?.tenantUid
  const { workspaceMetadata, workspaceOptions } = useContext(WorkspaceContext) 
  const isPreview = globalSiteState.drawerState.isPreview
  const previewData = isPreview ? globalSiteState.sites.find(url=>url.id===globalSiteState.selectedSite): globalSiteState.drawerState.previewData!== null &&  globalSiteState.drawerState.previewData!== undefined ? globalSiteState.drawerState.previewData.previewData :{}
  const handleUrlOpen = (val) => {
    window.open(val, '_blank')
  }

  useEffect(()=>{
    if(isPreview){
      const filtered=previewData.childUrls.filter(item=>item.crawlStatus===true)
      setChecked(filtered)
      if(previewData.childUrls.length>0){
        setUncrawled(previewData.childUrls.filter(e=>e.crawlStatus===false))
      }
      setRecords(previewData.childUrls)
    }
    else{
      if(previewData.length>0){
        setIsLoading(false)
      setRecords(previewData)
    }else {
      setIsLoading(false)
      setRecords([])
    }
    }
  },[globalSiteState.sites])
  //--------------------------- Event Handlers -------------------------

  // Handle checkbox check event
  const handleCheckBoxEvent = (event,element) =>{
    const isChecked = event.target.checked
    if (isChecked) {
      !checked.includes(element) && setChecked((currentState)=>[...currentState, element]) 
    } else {
      checked.includes(element) && setChecked((currentState)=>currentState.filter((f) => f !== element))
    }
  }

  // handle clicking on recrawl icon for  url
  const handleRecrawlEvent = (id,siteUrl) => {
    setReCrawlingSite(id)
    updatePreviewUrlRecrawlStatus(id,siteUrl,previewData.docName,previewData.sharepointClientId,previewData.sharepointClientSecret,previewData.sharepointAzureTenantId)
  }

  //Handle clicking on Crawl or Crawl Selected button
  const handlePublishSelectedEvent = () =>{     
    let selectedIds=[]
    let selectedWebUrls=[]
    let modifiedSites=[]
    if(!isPreview){
      selectedIds=records.filter(item=>checked.includes(item)).map(element=>element.id)
      selectedWebUrls=records.filter(item=>checked.includes(item)).map(element=>element.weburl)
      modifiedSites=records.map(item=>{
        const matchedSite=!checked.find(site=>site.id===item.id)
        if(matchedSite){
          return{...item,crawlStatus:false}
        }
          return item
      })
      publishSelectedUrls(globalSiteState.drawerState.previewData,selectedIds,selectedWebUrls,modifiedSites)
    }
    else{
      selectedIds=uncrawled.filter(item=>checked.includes(item)).map((ele)=>ele.id)
      selectedWebUrls=uncrawled.filter(item=>checked.includes(item)).map((ele)=>ele.weburl)
      modifiedSites=records.map(item=>{
        const matchedSite=selectedIds.find(site=>site===item.id)
        if(matchedSite){
          return{...item,crawlStatus:true}
        }
          return item
      })
      publishSelectedUrls(previewData,selectedIds,selectedWebUrls,modifiedSites,previewData.id)
    }
  }


  // ----------------------------------Backend API calls. -----------------------------------

  // Recrawl a single url or document
  const updatePreviewUrlRecrawlStatus = async (id,url,baseUrl,clientId,clientSecret,azureTenantId) => {
    const options = { method: 'POST', url: 'InputFiles/sharepoint' }
    const urlBody = { operation: 'recrawl', tenantid: tenantId, id, azure_site_url: url, sharepoint_site_url: previewData.docName,client_id: previewData.sharepointClientId, azure_tenant_id:previewData.sharepointAzureTenantId}
    if (!globalSiteState.hasCertificate) {
      urlBody.client_secret = previewData.sharepointClientSecret
    }
    try {
      const response = await createOrUpdateAsync(options, urlBody)
      if (response.code === 200) {
         setReCrawlingSite('')
         setRecrawledSites([...recrawledSites,id])
      }   
    } catch (error) {
      setReCrawlingSite('')
    }
  }
    
  //Handle Publish Selected Url Event
  const publishSelectedUrls = async(formInputs, selectedIds, selectedUrls, modifiedUrls, id) => {
    setDrawerLoading('Publishing selected urls and/or documents...')
    const payload = {}
    if (isPreview) {
      payload.sharepointClientId = formInputs.sharepointClientId
      payload.sharepointAzureTenantId = formInputs.sharepointAzureTenantId
      payload.docName = previewData.docName
       if (!globalSiteState.hasCertificate) 
        payload.sharepointClientSecret = formInputs.sharepointClientSecret
    } else {
      payload.inputs = {}
      payload.inputs.title= formInputs.inputs.title
      payload.inputs.description = formInputs.inputs.description
      payload.inputs.frequency = formInputs.inputs.frequency
      payload.inputs.client_id = formInputs.inputs.client_id
      payload.inputs.azure_tenant_id = formInputs.inputs.azure_tenant_id
      payload.inputs.site_url =formInputs.inputs.site_url
       if (!globalSiteState.hasCertificate) 
        payload.inputs.client_secret = formInputs.inputs.client_secret
    }
    const options={method:'POST',url:'InputFiles/sharepoint'}
    const urlBody = { operation: 'create_republish_sharepoint_site', formInputs:payload, selectedIds, selectedUrls, modifiedUrls, isPreview, id, workspace: workspaceMetadata }
    try {
      const response = await createOrUpdateAsync(options, urlBody)
      if (response.code === 200) 
         onCreatePublishSuccess()
      else
      onCreatePublishSuccessLater()
    } catch (error) {
      onCreatePublishSuccessLater()
    }
      setRecords([])
  }

    //---Single URL delete----//
  const deleteSiteOrDocument=async(id,site)=>{
    setDeletingSiteId(id)
    const options={method:'POST',url:'InputFiles/sharepoint'}
    const modifiedUrls=records.map((item)=>{
      if(item.id===id)
        return {...item,crawlStatus:false}
      return item
    })
    const urlBody={
      operation:'delete_site_or_document',
      pineconeUrl:site.weburl,
      modifiedUrls:modifiedUrls,
      id:previewData.id,
    }
    try {
      const response = await createOrUpdateAsync(options, urlBody)
      if (response.code === 200) 
         refreshSiteList() 
    } catch (error) {
      onCreatePublishFailure(error)
    }
      setDeletingSiteId('')
  }

  // ------------------------------------ End Backend API Calls -----------------------------------

  if(globalSiteState.sites.length===0 && isLoading) return <div>
  <Box height='80vh' width='100%' display='flex' justifyContent='center' alignItems='center' flexDirection='column' >  
  <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500'size='xl'/>
  <Text my='2'>Crawling SharePoint sites</Text>
</Box>
  </div>
  else
  return (
          <Stack height='100vh'>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              p="15px"
              color="#374151"
            >
              <Box display="flex" alignItems="center">
                <Image src={back} alt="back" cursor="pointer" onClick={()=>closeDrawer(false)} />
                <Text fontSize="18px" mx="10px"> Preview </Text>
              </Box>
            </Flex>
            <Divider />
            <Stack p="15px" spacing='2rem' flexGrow={1} overflow='auto'>
                <Stack borderRadius="10px" border="1px solid #e2e8f0" padding="10px" spacing={4} overflow='scroll'>
                  {
               records.length>0?(
                 records!==null && records.map((site) => {
                  const bagdeProps = site.crawlStatus? greenBadgeProps:orangeBadgeProps
                  return (
                    <Stack key={site.id} color="#6b7280" fontSize='14px' maxHeight='100%'>
                      <Flex justifyContent="space-between" alignItems="center" >
                        <Box display="flex" alignItems="center">
                          <Checkbox _checked={{ "& .chakra-checkbox__control": {background: "#4F46E5",border:"none" }}} _disabled={{ "& .chakra-checkbox__control": {opacity:0.2 }}}  me='10px' isDisabled={isPreview?site.crawlStatus:false} isChecked={checked.includes(site)} onChange={(event)=>{handleCheckBoxEvent(event,site)}} />
                          <Text width="100%" maxWidth="400px" color={isPreview && site.crawlStatus?"#6b7280":'inherit'} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" title={site.weburl} fontSize='14px' >{site.weburl}</Text>
                          {isPreview&& <Text as='span' {...bagdeProps} >{site.crawlStatus? 'Crawled':'Uncrawled'}</Text>}
                        </Box>
                        {!isPreview? (
                          <Box display="flex" alignItems="center">
                            <Text ms='10px' cursor="pointer" onClick={()=>{handleUrlOpen(site.weburl)}} ><RxExternalLink color="#6b7280" size='20px'/></Text>
                          </Box>
                          ):
                          site.crawlStatus ?
                          (
                            <HStack spacing='0px' align='center' justify='flex-start'>
                            {recrawlingSite===site.id?
                            (
                              <Flex align='center' transform='translateX(0)' transition='transform 0.1s ease-in-out' bg='#2563EB' px='10px' py='3px' color='white' borderRadius='5px'>
                                <TbRefresh size='12px'/>
                                <Text ml='10px' fontSize='12px'>Crawling...</Text>
                              </Flex>
                            )
                              :
                            deletingSiteId===site.id?(
                              <Flex align='center' transform='translateX(0)' transition='transform 0.1s ease-in-out' bg='#2563EB' px='10px' py='3px' color='white' borderRadius='5px'>
                                <HiOutlineTrash size='12px'/>
                                <Text ml='10px' fontSize='12px'>Deleting...</Text>
                              </Flex>
                            ):
                            (
                              <>
                              <Tooltip placement='top' label='Recrawl Url'><IconButton aria-label="Recrawl URL" variant='ghost' color={recrawledSites.includes(site.id)?'#2563EB':'#94A3B8'} px='0px' marginRight='40px' onClick={()=>handleRecrawlEvent(site.id,site.weburl)} fontSize='20px'>{<TbRefresh/>}</IconButton></Tooltip>
                              <Tooltip placement='top' label='Delete Url'>
                              <IconButton aria-label="Delete URL" variant='ghost' color='#94A3B8' px='0px' marginLeft='0px' fontSize='20px'
                                onClick={()=>{
                                  deleteSiteOrDocument(site.id,site)}}>
                                {<HiOutlineTrash/>}
                                </IconButton>
                              </Tooltip>   
                              </>
                            )

                              }
                            </HStack>
                          ):<></>
                        }
                      </Flex>
                     
                    </Stack>
                  )
                })
               ):(
                <Stack display='flex' justifyContent='center' width='100%'>
                  <Box mt='100px' mb='30px' mx='0' display='flex' justifyContent='center'>
                    <Box px='50px' width='500px' height='175px' borderRadius='10px' border='1px solid #e2e8f0' bg='#f8f9fd' display='flex' flexDirection='column' justifyContent={'center'} alignItems='center'>
                        <Image src={notFound} alt="back" cursor='pointer' width='50px' height='50px' />
                        <Text mt='20px' key='success'>No sites or documents found</Text>      
                    </Box>
                  </Box>
                  <Box my='20px' display='flex' justifyContent='center' > <Button minW="100px" bg="#1e90ff" color="#fff" fontSize='16px' fontWeight='400' onClick={()=>closeDrawer(true)} > Go to List</Button> </Box>
                </Stack>
               )
                  }
                </Stack>
                <Box my='2' display='flex' justifyContent='flex-end' spacing='10px'>
                    {
                      records.length>0?(
                    <HStack spacing='10px'>
                      <Button
                        disabled={isPreview?(uncrawled.filter(item=>checked.includes(item)).length===0?true:false):(checked.length===0?true:false)}
                        bg={isPreview?"#2563EB":"#1E90FF"}
                        color="#fff" fontSize='14px' onClick={isPreview ? ()=>handlePublishSelectedEvent(false):  ()=>handlePublishSelectedEvent(true)} >
                        {isPreview? 'Publish Selected' : 'Publish'}
                      </Button>
                      {isPreview &&
                          <Button
                          bg="#2563EB"
                          color="#fff" fontSize='14px' onClick={()=>closeDrawer(false)} >
                          Close
                          </Button>
                      }
                    </HStack>
                      ):(<></>)
                    }
                </Box>
            </Stack>
          </Stack>
  )
}

export default SharepointPreviewPage
