
import {useState,useEffect, useContext,useRef} from "react"
import {
    Divider,Button,
    Flex,Stack,Box,InputGroup,InputRightElement,Input,Image,Text, UnorderedList, ListItem,Spinner,IconButton,Tooltip, useToast,Progress
  } from "@chakra-ui/react"
import {useQuery,useMutation,useQueryClient} from 'react-query'
import {FiSearch,FiEdit,FiUpload} from "react-icons/fi"
import { AiOutlineQuestionCircle } from "react-icons/ai"
import { HiOutlineTrash, HiOutlineChatBubbleLeftRight, HiOutlineWrenchScrewdriver } from 'react-icons/hi2'
import { HiOutlineLightBulb} from 'react-icons/hi'
import Confetti from 'react-confetti'

import Pagination from '../../../commonComponents/Pagination'
import {useKnowledgeGapsContext} from '../context/KnowledgeGapsContext'
import DeleteModal from 'app/commonComponents/DeleteModal'
// import ExplanationModal from './ExplanationModal'
import ExplanationModal from '../../../../explainability_ui/ExplanationModal'
import norecord from '../../../../assets/norecord.png'

const KnowledgeGapsTable = () => {
    const {openDrawer,setSelectedGap,globalState,deleteGap,replaceSASUrls,fetchChatData} = useKnowledgeGapsContext()
    const [searchQuery,setSearchQuery]=useState('')  
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isExplanationModalOpen, setIsExplanationModalOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const chatDataRef = useRef(null)
    const records = globalState.gaps
    const top = currentPage * itemsPerPage
    const skip = top - itemsPerPage
    const totalRecordsToBeDisplayed = records.filter((data)=>{
        return  ((searchQuery==="")||(data.utterance.toLowerCase().includes(searchQuery.toLowerCase())))
       })
    const currentPageRecords = totalRecordsToBeDisplayed.slice(skip,top)

    const pagesLength=Math.max(1,Math.ceil(totalRecordsToBeDisplayed.length/ itemsPerPage))
    const deleteModalMessage = useRef('')

    /** ------------------------- Event Handlers ------------------ */
      /**  -------------------------------- Handle clicking on explanation icon -------------------------- */
    const handleExplanation = async (id) => {
        chatDataRef.current = await fetchChatData(id)
        setSelectedGap(id)
        setIsExplanationModalOpen(true)
    }
    /**  -------------------------------- Handle clicking on delete gap icon -------------------------- */
    const handleGapDelete = (gap) => {
        setSelectedGap(gap.id)
        setIsDeleteModalOpen(true)
        deleteModalMessage.current = `Are you sure to you want to delete ${gap.utterance}?` 
    }

    /**  -------------------------------- Handle clicking on Fix now -------------------------- */
    const handleFixNow = (id) => {
        setSelectedGap(id)
        openDrawer()
    }

    /**  -------------------------------- Handle clicking on View Chat -------------------------- */
    const handleViewChat = async (id) => {
        const chatData = await fetchChatData(id)
        setSelectedGap(id)
        openDrawer({page:'viewChat',data:chatData})
    }

     /**  -------------------------------- Handle changing items per page -------------------------- */
    const handleItemsPerPage=(e)=>{
        setItemsPerPage(e.target.value)
        setCurrentPage(1)
    }
    
     /**  -------------------------------- Handle clicking on pagination buttons -------------------------- */
    const handlePageNumber=(e)=>{
        setCurrentPage(e)
        }
  
        /** --------------------------------End Event Handlers -------------------------------- */
    return (
        <Stack>
            <Flex justify="space-between" padding='10px 1px' alignItems="center" paddingRight='10px' >
                <Text color='#000' fontWeight={500} fontSize='18px' data-testid='kg_table_heading'>Knowledge Gaps</Text>
                <InputGroup className="search-wrapper" maxW='285px' h='36px' data-testid='kg_table_search'>
                    <Input placeholder="Search" fontSize='14px' color='#617182' value={searchQuery} onChange={(e)=> {
                        setCurrentPage(1)
                        setSearchQuery(e.target.value)
                        }} />
                    <InputRightElement pointerEvents="none" children={<FiSearch size='24px' color='#617182'/>} />
                </InputGroup>
            </Flex>
            {
            globalState.isFetchingGaps ?
                ( 
                <Flex direction="column" justifyContent='center' alignItems='center' >
                    <Spinner thickness='4px' speed='0.65s'emptyColor='gray.200' color='#2ac769' size='xl'data-testid='kg_table_fetching_gaps_spinner'
                    />
                    <Text my='2' data-testid='kg_table_fetching_gaps_text'>Fetching Knowledge Gaps ...</Text>
                </Flex>
                ):
                records.length===0 ? 
                (
                    <Flex flexDir="column" justifyContent='center' alignItems='center' data-testid='kg_table_no_gaps_container'>
                        <Image width='140px' height='140px' src={norecord} alt='no records' data-testid='kg_table_no_gaps_image'/>
                        <Text as='h5' fontSize='18px' fontWeight='bolder' my='20px' textAlign='center' data-testid='kg_table_no_gaps_title' >No Knowledge gaps found!</Text>
                    </Flex>
                
                ):
                totalRecordsToBeDisplayed.length===0 ?
                (
                <Flex flexDir="column" justifyContent='center' alignItems='center' data-testid='kg_table_no_gaps_container'>
                        <Image width='140px' height='140px' src={norecord} alt='no records' data-testid='kg_table_no_gaps_image'/>
                        <Text as='h5' fontSize='18px' fontWeight='bolder' my='20px' textAlign='center' data-testid='kg_table_no_gaps_title' >No Knowledge gaps found!</Text>
                        <Text data-testid='kg_table_no_gaps_text'>Please retry with a different search query.</Text>
                    </Flex>
                ):
                currentPageRecords.map((item,index)=>{
                    const data = item
                    const aiQuery_index = globalState.query_responses.findIndex(response=>response.failedUtteranceId === data.id)
                    return (
                        <Flex key={index} padding='20px 0px' direction='column' gap='10px' alignItems='flex-start' alignSelf='stretch' borderBottom='1px solid #D1D5DB' data-testid={`kg_table_gap_container_${index}`}>
                            <Flex justifyContent='space-between' alignItems='flex-start' alignSelf='stretch'>
                                <Flex gap='10px' alignItems='center'>
                                    <AiOutlineQuestionCircle color='#2EC1C5' size='26px' data-testid='kg_table_gap_question_icon'/>  
                                    <Text fontSize='14px'  fontWeight='bolder' color='#000' data-testid='kg_table_gap_title' maxWidth='500px' textOverflow='ellipsis' overflow='hidden' whiteSpace='nowrap' title={data.utterance} >{data.utterance}</Text>
                                    <Flex gap='4px' alignItems='center' justify='center' padding='2px 5px' borderRadius='100px' border={`1px solid ${data.status==='missing'?'#E79B04':'#CF3626'}`}>
                                        <Text as='span' fontSize="12px" fontWeight='bolder' color={data.failureCause==='Not satisfied'?'#CF3626':'#E79B04'} data-testid={`kg_table_gap_badge_${data.failureCause==='Not satisfied'?'inaccurate':'missing'}`}>{data.failureCause==='Not satisfied'?'Inaccurate':'Missing'} knowledge</Text>
                                    </Flex>                            
                                </Flex>
                                <Flex gap='10px' alignItems='center' w='349px' justify='flex-end'>
                                    {aiQuery_index !== -1 && <Button variant='outline' h='29px' borderColor='#D1D5DB' borderRadius='6px' leftIcon={<HiOutlineLightBulb size='16px' color='#94A3B8' />} color="#6B7280" fontWeight={400} fontSize='12px' onClick={() => handleExplanation(data.id)} data-testid='kg_table_gap_explanation_button'>Explanation</Button>}
                                    <Button variant='outline' minW='104px' maxW='104px' h='29px' borderColor='#D1D5DB' borderRadius='6px' leftIcon={<HiOutlineChatBubbleLeftRight size='16px' color='#94A3B8'/>} color="#6B7280" fontWeight={400} fontSize='12px' onClick={()=>handleViewChat(data.id)} data-testid='kg_table_gap_view_chat_button'>View Chat</Button>
                                    {
                                        !data.fixed?
                                            <Button bg="#2563eb" minW='88px' maxW='88px' h='29px' color="#fff" fontWeight={400} fontSize='12px' leftIcon={<HiOutlineWrenchScrewdriver size='16px' color='#fff'/>} onClick={()=>handleFixNow(data.id)} data-testid='kg_table_gap_fix_button'>Fix Now</Button>
                                            :
                                            <>
                                                <Flex position='relative' color='#2AC769' borderRadius='6px' alignItems='center' justifyContent='center' border='1px solid #2563EB' minW='88px' maxW='88px' h='29px' fontSize='14px' data-testid='kg_table_fixed_button'>
                                                    <Confetti recycle={false}  confettiSource={{ w: 10, h: 10, x:40 , y: 15 }} numberOfPieces={50} opacity={0.5} width='88px' height='29px' style={{ position: 'absolute', top: 0, left: 0, borderRadius: '6px' }} />
                                                    Fixed
                                                </Flex>
                                            </>                                            
                                    }
                                    {!data.fixed && <Tooltip placement='top' label='Delete Gap'><span><HiOutlineTrash size='24px' color='#CF3626' onClick={()=>handleGapDelete(data)} data-testid='kg_table_gap_delete_button' cursor='pointer'/></span></Tooltip>}
                                </Flex>
                            </Flex>
                        </Flex>
                        )
                })
            }
            <Box display='flex' justifyContent='space-between' width='100%' my='5' >
                <Box display='flex' alignItems='center' >
                    Show
                    <Box border='1px solid #e2e8f0' borderRadius='5px' mx='3'  >
                    <select onChange={handleItemsPerPage} style={{borderRadius:'5px',height:'30px'}} >
                        <option value='10'>10</option>
                        <option value='20' >20</option>
                    </select>
                    </Box>gaps per page</Box>
                <Pagination totalPages={pagesLength} currentPage={currentPage} onPageChange={handlePageNumber} />
            </Box>
            <DeleteModal isOpen={isDeleteModalOpen} closeModal={() => setIsDeleteModalOpen(false)} callback={deleteGap} message={deleteModalMessage.current} />
            <ExplanationModal isOpen={isExplanationModalOpen} closeModal={() => setIsExplanationModalOpen(false)} explainableData={globalState.query_responses.find(response => response.failedUtteranceId === globalState.selectedGap)} replaceSASUrls={replaceSASUrls} selectedGap={globalState.gaps.find(gap => gap.id === globalState.selectedGap)} chatData={ chatDataRef.current} />
        </Stack>
    )
}

export default KnowledgeGapsTable

  