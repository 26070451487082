import React,{useState,useContext} from "react"
import {Box,Text, Divider, Flex,Spinner,Stack, InputGroup, Input, Select,Button,Textarea,Radio,RadioGroup,Image} from "@chakra-ui/react"
import { useMutation,useQueryClient } from 'react-query'
import axios from 'axios'
import { GrClose } from "react-icons/gr"
import {TbCapture } from "react-icons/tb"
import { AiOutlinePlusCircle } from "react-icons/ai"
import back from '../../../assets/back.png'
import { useServicesContext } from "services/apiServices"
import {WorkspaceContext} from '../../commonComponents/Layout/Layout'

const CrawlUrl = ({closeDrawer,page,totalUrls,urlToEdit,onAddUrlSuccess,onAddUrlFailure,onEditUrlSuccess,onEditUrlFailure,onCreateUrlSuccess,onCreateUrlFailure,parent}) => {
  const [ urlInputs,setUrlInputs]=useState({url:'',website: page==='crawlForm'?'':urlToEdit.label,description:page==='crawlForm'?'':urlToEdit.description?urlToEdit.description:'',frequency:'No update',publish:'Unpublished',crawl:'false',allowed:[],child:'100',metatags:{}})
  const initialCrawlErrorState={childError:'',websiteError:'',allowedDomainError:'',urlError:'',descError:''}
  const [crawlErrors,setCrawlErrors]=useState(initialCrawlErrorState)
  const [isFetching,setIsFetching] = useState(false)
  const [domainsValue,setDomainsValue]=useState('')
  const [allowedDomains,setAllowedDomains]=useState([])
  const [nestedUrlValue,setNestedUrlValue] = useState('false')
  const [maxChildUrlLevel,setmaxChildUrlLevel]=useState('0')
  const { workspaceMetadata } = useContext(WorkspaceContext)
  const {createOrUpdateAsync,apiCall} = useServicesContext()
  // const {selectedGapItem} = useServicesContext()

  const addUrl=useMutation(apiCall,{
    onMutate:()=>{
     setIsFetching(true)
    },
    onSuccess:(data)=>{
      const childUrls=[]
      if(data?.data.child_urls){
        Object.entries(data?.data.child_urls).forEach(([key,value],parentIndex)=>{ 
          if(value.length>0){
            const grandChildUrls=[]
            value.forEach((item,index)=>{
            grandChildUrls.push({id:`${parentIndex+1}.${index+1}`,url:item,crawlStatus:true,grandChild:[]})
            })
            childUrls.push({id:parentIndex+1,url:key,crawlStatus:true,grandChild:grandChildUrls})
          }
          else{
            childUrls.push({id:parentIndex+1,url:key,crawlStatus:true,grandChild:[]})
          }
        })
      }
      onAddUrlSuccess({previewUrl:{
        url:data.data.base_url,
        crawlStatus:'Published',
        website:urlInputs.website,
        frequency:urlInputs.frequency,
        description:urlInputs.description,
        allowedDomains,
        maxChildUrlLevel,
        childUrls
        }
      })
    },
    onError:(error)=>{
      onAddUrlFailure(error)
    }
  })

  const createUrl=useMutation(apiCall,{
    onSuccess:()=>{
     onCreateUrlSuccess(urlInputs?.url)
    },
    onError:(error)=>{
      onCreateUrlFailure(error)
    }   
  })

  const handleCrawlUrlUpload=async (e)=>{
    e.preventDefault()
    setCrawlErrors({websiteError:'',childError:'',urlError:'',allowedDomainError:''})
    if(page==='crawlForm' ){
      setCrawlErrors((prevError)=>({...prevError,urlError:'',childError:''}))
      // setGlobalUrlsState((prev)=>({...prev,editCrawlUrl:false}))
      setNestedUrlValue('false')
      const isValidUrl = (val) => {
        const urlRegex = /^(?!^[0-9])(https?|ftp):\/\/([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/
        return urlRegex.test(val)
      }
      let url=urlInputs?.url
      let validUrl=false
      if (url.startsWith("http://www.") || url.startsWith("https://www.")) {
        validUrl =isValidUrl(url)
      }
      else if(url.startsWith("http://") || url.startsWith("https://")){
        validUrl =isValidUrl(url)
      }
      else if (!url.startsWith("http://") || !url.startsWith("https://")) {   
          url = "https://" + url
          validUrl =isValidUrl(url)
      }
      let validCount = true
      let value=urlInputs?.child
      const clearedValue = value.replace(/[^\d]/g, '')
      validCount = /^\d+$/.test(clearedValue) && Number(clearedValue) >= 0 && Number(clearedValue) <= 1000
      let filtered=[]
      let existingUrlFiles=false
      filtered=totalUrls.filter((item)=>{
        return item.docName===url
      })
      if (filtered.length>0) existingUrlFiles=true
      if(urlInputs.url!=='' && urlInputs.child!=='' && urlInputs.website!=='' && validCount && validUrl && !existingUrlFiles ){
        let hierarchicalTags=''
        let simpleTags=''
        let cumulativeData
        cumulativeData = `${hierarchicalTags}${simpleTags}team:${workspaceMetadata}`
        let updatedAllowedDomains=allowedDomains.map((item)=>{
          return item.value
        })
        let filtered=allowedDomains.filter((item)=>{ return (item.value === urlInputs?.url)})
        let additionalDomains=[]

        if(filtered.length===0) {
          let allowedDomainDefault=url.replace(/^https?:\/\//, '').replace(/^www\./, '').split('/')[0]
          additionalDomains=[...updatedAllowedDomains,allowedDomainDefault]
        } else {additionalDomains=updatedAllowedDomains}
       
        const baseUrl = urlInputs.url.startsWith("http:") || urlInputs.url.startsWith("https://") ? urlInputs.url : `https://${urlInputs.url}`
       
        let uniqueDomanins=[...new Set(additionalDomains)]
        const options={method:'POST',url:`InputFiles/${parent==='gap'?'create_url':'add_url'}`,variables:'url'}
        const payload = parent==='gap'? 
        {baseUrl,metadata:cumulativeData,workspace:workspaceMetadata,label:urlInputs.website,frequency:urlInputs.frequency,description:urlInputs.description,childUrls:[],pineconeChildUrls:{base_url:baseUrl,child_urls:{}},allowedDomains,crawlStatus:'Published',childCount:maxChildUrlLevel}
        :{urls:[baseUrl],url_filters:uniqueDomanins,child:maxChildUrlLevel,nested:nestedUrlValue}
        if(parent==='gap')
          createUrl.mutateAsync({options,payload})
          // onFixUploadGap(selectedGapItem,'Crawl Url')
        // }
        else
          addUrl.mutateAsync({options,payload})
      }
      else{
        if(urlInputs.url==='') setCrawlErrors((error)=>({...error,urlError:'URL is Blank'}))
        if(urlInputs.url!=='' && !validUrl) setCrawlErrors((error)=>({...error,urlError:'Please enter valid URL'}))
        if(urlInputs.website==='') setCrawlErrors((error)=>({...error,websiteError:'Website title is blank'}))
        if(urlInputs.child==='') setCrawlErrors((error)=>({...error,childError:'Child url count is blank'}))
        if (urlInputs.child!==""&&!validCount) {setCrawlErrors((error)=>({...error,childError:'Please enter the valid value between 0 to 1000'}))}
        if(validUrl && filtered.length>0) setCrawlErrors((error)=>({...error,urlError:'URL already crawled'}))
      }
    }
    else{
      handleSubmitURLEdit()
    }
  } 

  const handleSubmitURLEdit = async ()=>{

    const urlObj = {...urlToEdit,label:urlInputs.website,description:urlInputs.description}
    const options={url:'InputFiles/update_url',method:'PUT',variables:'editUrl'}
    const urlBody={urlId: urlObj.id,baseUrl:urlObj.docName,label:urlObj.label,frequency:urlObj.frequency,description:urlObj.description}
    const response = await createOrUpdateAsync(options,urlBody)
    if(response.status===200)
    onEditUrlSuccess()
  else
    onEditUrlFailure()
  }

  const handleChangeUrl=(e)=>{
    setUrlInputs({...urlInputs,[e.target.name]:e.target.value})
    let targetName = e.target.name
    if (targetName == 'description') {
      setCrawlErrors((error) => e.target.value.length > 2000
        ? { ...error, descError: 'The description needs to be 2000 characters or less' }
        : { ...error, descError: '' }
      )
    }
    if(urlInputs.website!=='') setCrawlErrors((error)=>({...error,websiteError:''}))
    if(urlInputs.url!=='') setCrawlErrors((error)=>({...error,urlError:''}))
  }

  const handleChangeNestedUrl=(value)=>{
    setNestedUrlValue(value)
  }

  const handleDefaultDomain=(url)=>{
    let value= url.replace(/^https?:\/\//, '').replace(/^www\./, '').split('/')[0]
    setDomainsValue(value)
   }
   const handleChangeAllowedDomains=(e)=>{
    setDomainsValue(e.target.value)
   }
   const handleAllowedDomains=()=>{
    setCrawlErrors((error)=>({...error,allowedDomainError:''}))
    const filteredDomains=allowedDomains.filter((e)=>{
      return e.value===domainsValue
    })
    if(filteredDomains.length>0){
      setCrawlErrors((error)=>({...error,allowedDomainError:'Domain already exists'}))
    }
    else{
      setAllowedDomains([...allowedDomains,{id:allowedDomains.length+1,value:domainsValue}]) 
    }
     setDomainsValue('')
   }
   const handleRemoveAllowedDomains=(item)=>{
    const filteredDomains=allowedDomains.filter((e)=>{
      return e.id!==item
    })
    setAllowedDomains(filteredDomains)
   }

   const handlemaxChildLevels=(e)=>{
      setmaxChildUrlLevel(e.target.value)
    }

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" p='15px' color='#374151'>
       <Box display='flex' alignItems='center' >
        <Image src={back} alt="back" cursor='pointer' onClick={closeDrawer} data-testid='crawl_url_back' />
        <Text fontSize="18px" mx='10px'>{page==='crawlForm' ?'Crawl URL': 'Edit'}</Text>
       </Box>
      </Flex>
      <Divider />
      {isFetching?
        <Box height='80vh' width='100%' display='flex' justifyContent='center' alignItems='center' flexDirection='column' >  
          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500'size='xl'/>
          <Text my='2'>'Fetching urls'</Text>
        </Box>
        :
        <form onSubmit={handleCrawlUrlUpload} >
        <Stack p='15px' >
          {page=='crawlForm' && 
            <InputGroup mb='5px' flexDirection='column' >
              <Box display='flex' alignItems='start' >
                <Text>Enter URL</Text><Text as='span' color='red' fontSize='10px'>*</Text>
              </Box>
              <Input data-testid='crawl_url_input' placeholder="URL" name='url' value={urlInputs.url} onChange={handleChangeUrl} onBlur={()=>{handleDefaultDomain(urlInputs.url)}} />
              <Box height='15px' >{crawlErrors.urlError && <Text color='red' fontSize='12px' >{crawlErrors.urlError}</Text>}</Box>
            </InputGroup>
          }     
            <InputGroup mb='5px' flexDirection='column' >
              <Box display='flex' alignItems='start' ><label>Website Title</label><Text as='span' color='red' fontSize='10px'>*</Text></Box>
                <Input data-testid='crawl_website_title' placeholder="Title" name='website' value={urlInputs.website} onChange={handleChangeUrl} />
              <Box height='15px' >{crawlErrors.websiteError && <Text color='red' fontSize='12px' >{crawlErrors.websiteError}</Text>}</Box>
            </InputGroup>
            <InputGroup mb='5px' flexDirection='column'>
              <label>Description</label>
                <Textarea data-testid='crawl_description' name='description' rows={3} rezize='none' value={urlInputs.description}  onChange={handleChangeUrl} />
                <Box height='15px' >{crawlErrors.descError && <Text color='red' fontSize='12px' >{crawlErrors.descError}</Text>}</Box>
            </InputGroup>
          {page==='crawlForm' && parent!=='gap' &&
            <>
              <InputGroup mb='5px' flexDirection='column' >
                <Flex alignItems='center' >
                  <Text>Crawl nested URLs?</Text>
                  <Box display='flex'>
                  <RadioGroup defaultValue={urlInputs.crawl} onChange={handleChangeNestedUrl} >
                    <Radio data-testid='crawl_yes' name='crawl' value='true' mx='3'   >Yes</Radio>
                    <Radio data-testid='crawl_no' name='crawl' value='false' mx='3' >No</Radio>
                  </RadioGroup>
                </Box>
                </Flex>
                <Box height='15px' >{crawlErrors.urlError && <Text color='red' fontSize='12px' ></Text>}</Box>
              </InputGroup>
              <InputGroup mb='5px' flexDirection='column'>
                <Text >Allowed Domains</Text>
                <Flex>
                  <Input data-testid='crawl_allowed' placeholder="Urls" name="allowed" onChange={handleChangeAllowedDomains} value={domainsValue} />
                  <Button padding='8px' background='transparent' disabled={domainsValue!=='' ?false:true}  cursor={domainsValue!=='' ?'pointer':'not-allowed'} borderRadius='5px' ms='5px' border='1px solid #1e90ff' height='40px' onClick={handleAllowedDomains}><AiOutlinePlusCircle  size={'24px'} color="#1e90ff" /></Button>
                </Flex>
                <Box height='15px' >{crawlErrors?.allowedError && <Text color='red' fontSize='12px' >{crawlErrors.allowedError}</Text>}</Box>
                {
                  allowedDomains && allowedDomains.map((item,index)=>{
                    return(
                      <Box display='flex' alignItems='center' flexWrap='wrap' key={index} my='1' >
                        <Text fontSize='14px' >{item.value}</Text>
                        <Text display='flex' alignItems='center' mx='3' cursor='pointer' onClick={()=>{handleRemoveAllowedDomains(item.id)}} ><GrClose  size='10px' /></Text>
                      </Box>
                      )
                  })
                }
              </InputGroup>
              <InputGroup mb='5px' flexDirection='column' >
                <Box display='flex' alignItems='start'><Text>How much do you want to crawl?</Text><Text as='span' color='red' fontSize='10px'>*</Text></Box>
              <Select data-testid='crawl_level' fontSize='14px' value={maxChildUrlLevel} bg='#fff' textOverflow='ellipsis' overflow='hidden' onChange={handlemaxChildLevels}>
                <option style={{display:'none'}} value='0' >{nestedUrlValue==='false'?'Crawl only the submitted URL - 0 level':'Crawl URL and the links found in URL - 1 level'}</option>
                {
                  nestedUrlValue==='false'?<option value='0'>Crawl only the submitted URL - 0 level</option>:
                  <>
                    <option value='0' >Crawl URL and the links found in URL - 1 level</option>
                    <option value='500' >Crawl URL, level 1 links, and the links found in those links - 2 levels</option>
                  </>
                }
              </Select>
              <Box height='15px' >{crawlErrors.childError && <Text color='red' fontSize='12px' >{crawlErrors.childError}</Text>}</Box>
              </InputGroup>
            </>
          }
          <Box display='flex' justifyContent='flex-end' >
            <Button disabled={page==='crawlForm'?(!urlInputs.url || urlInputs.description.length > 2000):(!urlInputs.website || urlInputs.description.length > 2000)} data-testid='crawl_url_preview' type='submit' minW="100px" leftIcon={(page==='crawlForm' && !parent==='gap')?<TbCapture />:null} bg={parent==='gap'?'#2563eb':"#1e90ff"} color="#fff" fontSize='16px' fontWeight='400' > {parent==='gap'?'Fix gap with Crawled Url' :page==='crawlForm'?'Preview':'Save'}</Button>
          </Box>          
        </Stack>
      </form>
      }

    </Box>
  )
}

export default CrawlUrl