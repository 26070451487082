import {HiOutlineWrench,HiOutlineTicket,HiOutlineUserGroup} from 'react-icons/hi2'
import { TfiComments } from "react-icons/tfi"
import { MdOutlineSupportAgent } from "react-icons/md"
import { BsSliders } from "react-icons/bs"
import {RiListSettingsFill} from 'react-icons/ri'
import {SlGraph,SlSpeedometer} from 'react-icons/sl'
export const sidebarData=[
    {
        exact: true,
        isNav: true,
        name: 'Virtual Agent Mgmt',
        label: 'Virtual Agent Mgmt',
        icon: <HiOutlineUserGroup/>,
        allowedRole: ['bot_analyst', 'bot_admin', 'tenant_admin', 'botstudio_user'],
        id:1,
        subMenuItems:[
            {
                path: '/botmanagement/dashboard',
                exact: true,
                isNav: true,
                name: 'Dashboard',
                label: 'Dashboard',
                icon: <SlSpeedometer />,
                allowedRole: ['bot_analyst', 'bot_admin'],
            },
            {
                path: '/botmanagement/reports',
                exact: true,
                isNav: true,
                name: 'Reports',
                label: 'Reports',
                icon: <SlGraph/>,
                allowedRole: ['bot_analyst', 'bot_admin'],
            },
            {
                path: '/botmanagement/notifications',
                exact: true,
                isNav: true,
                name: 'Notifications',
                label: 'Notifications',
                icon: 'fa fa-bell',
                allowedRole: ['bot_analyst', 'bot_admin'],
            },
            {
                path: '/botautomation/teams/team1/botTriggers',
                exact: true,
                isNav: true,
                isActive:true,
                name: 'Bot Triggers',
                label: 'Bot Triggers',
                icon: 'fa fa-arrow-circle-o-right',
                allowedRole: ['asc_admin'],
            },
            {
                path: '/botmanagement/studio',
                exact: true,
                isNav: true,
                name: 'Bot Studio',
                label: 'Bot Studio',
                icon: 'fa fa-comments-o',
                allowedRole: ['botstudio_user' ],
            },
            {
                path: '/botautomation/studio',
                exact: true,
                isNav: true,
                name: 'Bot Studio 2.0',
                label: 'Bot Studio 2.0',
                icon: 'fa fa-comments-o',
                allowedRole: ['botstudio_user' ],
            },
            {
                path: '/botmanagement/qna-studio',
                exact: true,
                isNav: true,
                name: 'Qna Studio',
                label: 'Qna Studio',
                icon: 'fa fa-comments-o',
                allowedRole: ['ASC Admin'],
            },
            {
                exact: true,
                isNav: true,
                name: 'Knowledge Mgmt',
                label: 'Knowledge Mgmt',
                allowedRole: ['bot_analyst', 'bot_admin', 'botstudio_user'],
                icon: 'fa fa-cubes',
                id:1.1,
                childMenuItems:[
                    {
                        path: '/aiconsole/documents',
                        exact: true,
                        isNav: true,
                        isActive:true,
                        name: 'Upload Documents',
                        label: 'Upload Documents',
                        allowedRole: ['aidochub'],
                        id:1.11
                    },
                    {
                        path: '/aiconsole/sharepoint',
                        exact: true,
                        isNav: true,
                        isActive:false,
                        name: 'SharePoint Integration',
                        label: 'SharePoint Integration',
                        allowedRole: ['aidochub'],
                        id:1.12
                    },
                    {
                        path: '/aiconsole/urls',
                        exact: true,
                        isNav: true,
                        isActive:false,
                        name: 'Crawl URL',
                        label: 'Crawl URL',
                        allowedRole: ['aidochub'],
                        id:1.13
                    },
                    {
                        path: '/aiconsole/articles',
                        exact: true,
                        isNav: true,
                        isActive:false,
                        name: 'Knowledge Articles',
                        label: 'Knowledge Articles',
                        allowedRole: ['aidochub'],
                        id:1.14
                    },
                    {
                        path: '/aiconsole/gaps',
                        exact: true,
                        isNav: true,
                        isActive:false,
                        name: 'Knowledge Gaps',
                        label: 'Knowledge Gaps',
                        allowedRole: ['aidochub'],
                        id:1.15
                    },
                    {
                        path: '/botmanagement/qna',
                        exact: true,
                        isNav: true,
                        name: 'Manage Q&A',
                        label: 'Manage Q&A',
                        icon: 'fa fa-question-circle',
                        allowedRole: ['bot_analyst', 'bot_admin'],
                    },
                    {
                        path: '/botmanagement/small-talk',
                        exact: true,
                        isNav: true,
                        name: 'Manage SmallTalk',
                        label: 'Manage SmallTalk',
                        allowedRole: ['aidochub'],
                    },
                    {
                        path: '/botmanagement/task-flows',
                        exact: true,
                        isNav: true,
                        name: 'Manage Task',
                        label: 'Manage Task',
                        icon: 'fa fa-tags',
                        allowedRole: ['bot_analyst', 'bot_admin'],
                    },
                    {
                        path: '/botmanagement/kbrules',
                        exact: true,
                        isNav: true,
                        name: 'KBRules',
                        label: 'KBRules',
                        icon: 'fa fa-users',
                        allowedRole: ['ASC Admin'],
                    },
                    {
                        path: '/botmanagement/kbs',
                        exact: true,
                        isNav: true,
                        name: 'KBS',
                        label: 'KBS',
                        icon: 'fa fa-users', 
                        allowedRole: ['ASC Admin']
                    },
                    {
                        path: '/botmanagement/requests',
                        exact: true,
                        isNav: true,
                        name: 'Requests',
                        label: 'Requests',
                        icon: 'fa fa-user-plus',
                        allowedRole: ['bot_analyst', 'bot_admin', 'tenant_admin'],
                    }
                ]
            },
            {
                // path: '/botmanagement/train',
                exact: true,
                isNav: true,
                name: 'Supervised Learning',
                label: 'Supervised Learning',
                icon: 'fa fa-book',
                allowedRole: ['bot_analyst', 'bot_admin', 'tenant_admin', 'botstudio_user'],
                id:1.2,
                childMenuItems:[
                    {
                        path: '/botmanagement/train',
                        exact: true,
                        isNav: true,
                        name: 'Train',
                        label: 'Train',
                        icon: 'fa fa-puzzle-piece',
                        allowedRole: ['bot_analyst', 'bot_admin'],
                    },
                    {
                        path: '/botmanagement/train-queue',
                        exact: true,
                        isNav: true,
                        name: 'Training Queue',
                        label: 'Training Queue',
                        icon: 'fa fa-code-fork',
                        allowedRole: ['bot_analyst', 'bot_admin'],
                    },
                    {
                        path: '/botmanagement/review-chat',
                        exact: true,
                        isNav: true,
                        name: 'Review Chat',
                        label: 'Review Chat',
                        icon: 'icon-speedometer',
                        allowedRole: ['bot_analyst', 'bot_admin'],
                    }
                ]
            },
            {
                // path: '/botmanagement/define-classification',
                exact: true,
                isNav: true,
                name: 'Taxonomy',
                label: 'Taxonomy',
                allowedRole: ['bot_admin'],
                icon: 'fa fa-tags',
                id:1.3,
                childMenuItems:[
                    {
                        path: '/botmanagement/define-classification',
                        exact: true,
                        isNav: true,
                        name: 'Define Classification',
                        label: 'Define Classification',
                        icon: 'fa fa-puzzle-piece',
                        allowedRole: ['bot_admin'],
                    },
                    {
                        path: '/botmanagement/classification-hirerchy',
                        exact: true,
                        isNav: true,
                        name: 'classificationHirerchy',
                        label: 'Classification Hierarchy',
                        icon: 'fa fa-code-fork',
                        allowedRole: ['bot_admin'],
                    },
                    {
                    path: '/botmanagement/simple-tags',
                    exact: true,
                    isNav: true,
                    name: 'simpleTags',
                    label: 'Simple Tags',
                    icon: 'fa fa-tags',
                    allowedRole: ['bot_admin'],
                    }
                ]
            },
            {
                // path: '/botmanagement/settings',
                exact: true,
                isNav: true,
                name: 'Settings',
                label: 'Settings',
                allowedRole: ['bot_admin', 'tenant_admin'],
                icon: <RiListSettingsFill/>,
                id:1.4,
                childMenuItems:[
                    {
                        path: '/botmanagement/widget-settings',
                        exact: true,
                        isNav: true,
                        name: 'widgetSettings',
                        label: 'Widget Settings',
                        icon: 'fa fa-puzzle-piece',
                        allowedRole: ['bot_admin'],
                    },
                    {
                        path: '/botmanagement/bot-access',
                        exact: true,
                        isNav: true,
                        name: 'botAccess',
                        label: 'Bot Access',
                        icon: 'fa fa-unlock',
                        allowedRole: ['bot_admin'],
                    },
                    {
                        path: '/botmanagement/MSTeams',
                        exact: true,
                        isNav: true,
                        name: 'MS-Teams',
                        label:'MS-Teams',
                        icon: 'fa fa-unlock', 
                        allowedRole: ['tenant_admin']
                    },
                    {
                        path: '/botmanagement/slack',
                        exact: true,
                        isNav: true,
                        name: 'Slack',
                        label: 'Slack',
                        icon: 'fa fa-unlock', 
                        allowedRole: ['tenant_admin']
                    },
                    {
                        path: '/botmanagement/common-bot-prompts',
                        exact: true,
                        isNav: true,
                        name: 'Common Bot Prompts',
                        label: 'Common Bot Prompts',
                        icon: 'fa fa-tags',
                        allowedRole: ['bot_admin']
                    }
                ]
            },
            {
                // path: '/botmanagement/botMessage',
                exact: true,
                isNav: true,
                name: 'Bot Configuration',
                label: 'Bot Configuration',
                icon: 'fa fa-wrench',
                allowedRole: ['ASC Admin'],
                id:1.5,
                childMenuItems:[
                    {
                        path: '/botmanagement/botMessage',
                        exact: true,
                        isNav: true,
                        name: 'Bot Messages',
                        label: 'Bot Messages',
                        allowedRole: ['ASC Admin'],
                        icon: 'fa fa-tags'
                    },
                    {
                        path: '/botmanagement/botContent',
                        exact: true,
                        isNav: true,
                        name: 'Bot Media',
                        label: 'Bot Media',
                        allowedRole: ['ASC Admin'],
                    },
                    {
                        path: '/botmanagement/qnaMedia',
                        exact: true,
                        isNav: true,
                        name: 'Qna Media',
                        label: 'Qna Media',
                        allowedRole: ['ASC Admin'],
                    },
                    {
                        path: '/botmanagement/botConfig/general',
                        exact: true,
                        isNav: true,
                        name: 'General Configuration',
                        label: 'General Configuration',
                        allowedRole: ['ASC Admin'],
                    },
                    {
                        path: '/botmanagement/botConfig/slot',
                        exact: true,
                        isNav: true,
                        name: 'Slots Configuration',
                        label: 'Slots Configuration',
                        allowedRole: ['ASC Admin'],
                    },
                    {
                        path: '/botmanagement/botConfig/card',
                        exact: true,
                        isNav: true,
                        name: 'Cards Configuration',
                        label: 'Cards Configuration',
                        allowedRole: ['ASC Admin'],
                    },
                    {
                        path: '/botmanagement/botConfig/api',
                        exact: true,
                        isNav: true,
                        name: 'Apis Configuration',
                        label: 'Apis Configuration',
                        allowedRole: ['ASC Admin'],
                    },
                    {
                        path: '/botmanagement/botConfig/action',
                        exact: true,
                        isNav: true,
                        name: 'Actions Configuration',
                        label: 'Actions Configuration',
                        allowedRole: ['ASC Admin'],
                    },
                    {
                        path: '/botmanagement/botConfig/apiServer',
                        icon: 'fa fa-tags',
                        exact: true,
                        isNav: true,
                        allowedRole: ['ASC Admin'],
                        name: 'botConfigApiServers',
                        label: 'Ext Servers Configuration',
                    },
                    {
                        path: '/botmanagement/botConfig/flow',
                        icon: 'fa fa-tags',
                        exact: true,
                        isNav: true,
                        allowedRole: ['ASC Admin'],
                        name: 'botConfigFlows',
                        label: 'Flows Configuration',
                        routeProps: {
                            filter: {
                                configType: ['flow'],
                            },
                        },
                    },
                    {
                        path: '/botmanagement/botConfig/cardTemplate',
                        icon: 'fa fa-tags',
                        exact: true,
                        isNav: true,
                        allowedRole: ['ASC Admin'],
                        name: 'botConfigFlows',
                        label: 'Card Template',
                        routeProps: {
                            filter: {
                                configType: ['cardTemplate'],
                            },
                        },
                    },
                    {
                        path: '/botmanagement/botConfig/script',
                        icon: 'fa fa-tags',
                        exact: true,
                        isNav: true,
                        allowedRole: ['ASC Admin'],
                        name: 'botConfigFlows',
                        label: 'Scripts Definition',
                        routeProps: {
                            filter: {
                                configType: ['script'],
                            },
                        },
                    },
                    {
                        path: '/botmanagement/botConfig/intent',
                        icon: 'fa fa-tags',
                        exact: true,
                        isNav: true,
                        allowedRole: ['ASC Admin'],
                        name: 'botConfigIntents',
                        label: 'Intents Configuration',
                        routeProps: {
                            filter: {
                                configType: ['intent'],
                            },
                        },
                    },
                ]
            },
            {
                path: '/botmanagment/AuditConfig',
                icon: 'fa fa-file-text',
                exact: true,
                isNav: true,
                name: 'Audit Config',
                label: 'Audit Config',
                allowedRole: ['ASC Admin'],
            },
            {
                // path: '/botmanagement/standardServer',
                exact: true,
                isNav: true,
                name: 'Standard Server',
                label: 'Standard Server',
                allowedRole: ['ASC Admin'],
                icon: 'fa fa-server',
                id:1.6,
                childMenuItems:[
                    {
                        path: '/botmanagement/standardServer',
                        exact: true,
                        isNav: true,
                        name: 'StandardServer',
                        label: 'Standard Server',
                        icon: 'fa fa-puzzle-piece',
                        allowedRole: ['ASC Admin'],
                    },
                    {
                        path: '/botmanagement/serverConfiguration',
                        exact: true,
                        isNav: true,
                        name: 'Server Configuration',
                        label: 'Server Configuration',
                        icon: 'fa fa-puzzle-piece',
                        allowedRole: ['ASC Admin'],
                    },
                    {
                        path: '/botmanagement/newServerConfiguration',
                        exact: true,
                        isNav: true,
                        name: 'New Server Configuration',
                        label: 'New Server Configuration',
                        icon: 'fa fa-puzzle-piece',
                        allowedRole: ['ASC Admin']
                    }
                ]
            }

        ]
    },
    {
        path: '/catalog/template-cardlist',
        exact: true,
        isNav: true,
        name: 'Service catalog',
        label: 'Service Catalog',
        icon: <HiOutlineWrench />,
        allowedRole: ['asc_admin'],
    },
    {
        path: '/agent/inbox',
        exact: true,
        isNav: true,
        name: 'Ticket Management',
        label: 'tickets',
        icon: <HiOutlineTicket />,
        allowedRole: ['ticket_user', 'ticket_agent', 'ticket_agent_supervisor', 'ticket_admin'],
    },
    {
        path: '/livechat/my-chats',
        exact: true,
        isNav: true,
        name: 'Live Chat',
        label: 'Live Chat',
        icon: <TfiComments />,
        allowedRole: ['chat_agent', 'chat_agent_admin', 'chat_agent_supervisor'],
    },
    {
        path: '/learning/program',
        exact: true,
        isNav: true,
        name: 'Learning Mgmt',
        label: 'learningApp',
        icon: 'fa fa-black-tie',
        allowedRole: ['content_author', 'coach'],
    },
    {
        path: '/user-management',
        exact: true,
        isNav: true,
        name: 'Admin App',
        label: 'Admin App',
        icon: <BsSliders/>,
        allowedRole: ['user_admin', 'tenant_admin'],
    },
    {
        path: '/virtualagent',
        exact: true,
        isNav: true,
        name: 'Virtual Agent',
        label: 'Virtual Agent',
        icon: <MdOutlineSupportAgent />,
        allowedRole: ['bot_user'],
    }
]