import {
    Divider,Button,
    Flex,Stack,Box,InputGroup,InputRightElement,Input,Image,Text, UnorderedList, ListItem,Spinner,IconButton,Tooltip, useToast,Progress
  } from "@chakra-ui/react"
  import {
    useQuery,useMutation,useQueryClient
  } from 'react-query'
  import React,{useState,useEffect, useContext,useRef} from "react"
  import {FiSearch,FiEdit,FiUpload} from "react-icons/fi"
  import norecord from '../../../assets/norecord.png'
  // import parse from 'html-react-parser'
  import { read, utils } from "xlsx"
  import axios from "axios"
  import marked from 'marked'
  import { v4 as uuidv4 } from 'uuid'
  import Papa from 'papaparse'
  import {GrFormPrevious,GrFormNext} from 'react-icons/gr'
  import {MdOutlineRemoveRedEye,MdOutlineStarBorder} from 'react-icons/md'
  import {HiOutlineTrash,HiOutlineFunnel} from 'react-icons/hi2'
  import {HiOutlineInformationCircle} from 'react-icons/hi'
  import Pagination from '../../commonComponents/Pagination'  
  import {useKnowledgeArticlesContext} from './context/KnowledgeArticlesContext'
  import MultiSelect from "./utils/MultiSelect"
  import { WorkspaceContext } from "app/commonComponents/Layout/Layout"
  import {useServicesContext } from "services/apiServices"

  const escapeRegExp = str => str.replace(/[.\*+?^${}()|[\]\\]/g, '\\$&')
  const replaceAll = (str, find, replace) => str.replace(new RegExp(escapeRegExp(find), 'g'), replace)
  const KnowledgeArticlesTable = () => {
    const {globalState,openCreatePage,openEditPage,openDetailPage,openDeleteModal,updateListPageProps,updateCreateEditPageProps,resetListPageProps,refetchArticles} = useKnowledgeArticlesContext()
    const {apiCall } = useServicesContext()
    const [searchQuery,setSearchQuery]=useState(globalState.listPageProps.searchQuery)  
    const records = globalState.articles
    const [filterRecords,setFilterRecords] = useState(globalState.listPageProps.filterRecords)
    const [currentPage, setCurrentPage] = useState(globalState.listPageProps.currentPage)
    const [itemsPerPage, setItemsPerPage] = useState(globalState.listPageProps.itemsPerPage)
    const queryClient = useQueryClient()
    const importRef = useRef()
    const [allowImport, setAllowImport] = useState(true)
    const toast = useToast()
    const cdnUri = 'https://primarycdn.blob.core.windows.net'
    const top = currentPage * itemsPerPage
    const skip = top - itemsPerPage
    const pages = []
    const { workspaceMetadata,workspaceOptions } = useContext(WorkspaceContext) 
    
    const recordswithWorkSpace=records.filter((element)=>{
      if(workspaceOptions.length>1){
        if(workspaceMetadata==='noWorkSpace') return element.workspace===null
        else if(element.workspace===workspaceMetadata){
          return element
        }
     }
     else{
       return element
     }
    })

    const totalRecordsToBeDisplayed = recordswithWorkSpace.filter((data)=>{
     return  ((searchQuery==="")||((data.articleData.title.toLowerCase().includes(searchQuery.toLowerCase()))||(data.articleData.description.toLowerCase().includes(searchQuery.toLowerCase()))))
    }).filter((data=>{
      return (filterRecords[0].value==='draft'?data.articleData.status[0].label==='Draft':true)
    }))
    const currentPageRecords = totalRecordsToBeDisplayed.slice(skip,top)

    const pagesLength=Math.max(1,Math.ceil(totalRecordsToBeDisplayed.length/ itemsPerPage))
    for (let i = 1; i <=pagesLength ; i++) {
    pages.push(i)
    }
    
    /** ------------------------------- API CALLS ----------------------------- */

   
    /** --------------------------- Call Backend API to upload scripted Answers ------------ */
    const mutation = useMutation(
      apiCall,
      {
          onSuccess:(data)=>{
              queryClient.invalidateQueries('articles')
              resetListPageProps()
              refetchArticles()
              toast({
                    title: 'Upload Success',
                    description: 'Scripted answers uploaded successfully' ,
                    status: 'success',
                    duration: 5000,
                    position: 'top-right',
                    isClosable: true,
                  })
              setAllowImport(true)    
          },
          onError: (err) => {
            queryClient.invalidateQueries('articles')
            resetListPageProps()
            refetchArticles()
            if(/timeout of \d+ms exceeded/.test(err.message))
              toast({
                  title: 'Upload Timeout',
                  description: 'Uploading scripted answers timedout. The upload may have suceeded' ,
                  status: 'warning',
                  duration: 5000,
                  position: 'top-right',
                  isClosable: true,
              }) 
            else
              toast({
                  title: 'Upload Error',
                  description: 'There was an error uploading scripted answers.' ,
                  status: 'error',
                  duration: 5000,
                  position: 'top-right',
                  isClosable: true,
              }) 
            setAllowImport(true)
          }
      }
    )
    const uploadScriptedAnswers = async (scriptedArticles) => {
      try{
        const payloadData = createArticlePayload(scriptedArticles)
        const urlBody ={
          operation:"upsert_ScriptedAnswers",
          payload:payloadData,
          workspace:workspaceMetadata
        }
        mutation.mutate({
          options: {
          url: `InputFiles/knowledgeArticles`,
          method: "POST",
          },
          payload: urlBody,
        })
      }catch(error){
        toast({
          title: 'Upload Error',
          description: 'There was an error uploading scripted answers.' ,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        })
        setAllowImport(true)
      }
    }
    /** -------------------------------------- END API CALLS ----------------------- */


    useEffect(()=>{
      setCurrentPage(globalState.listPageProps.currentPage)
      setItemsPerPage(globalState.listPageProps.itemsPerPage)
      setSearchQuery(globalState.listPageProps.searchQuery)
      setFilterRecords(globalState.listPageProps.filterRecords)
    },[globalState.listPageProps])

    /**   --------------- HELPER FUNCTIONS ----------------- */
    /** ---------------------------- Check that Uploaded Scripted Answer Excel file has the expected format ------------- */
    const validateExcelData = (jsonData) => {
      const desiredColumnCount = 3
      const desiredColumnNames = ['custom_questions', 'custom_answers','properties']
  
      if (jsonData[0].length !== desiredColumnCount) {
        return false
      }

      for (let i = 0; i < desiredColumnCount; i++) {
        if (jsonData[0][i] !== desiredColumnNames[i]) {
          return false
        }
      }

      return true
    }

    /** -------------------------- Create Article data from imported scripted answer as will be stored in DB -----------------  */
    const createArticlePayload = (data) => {
      const result = {
        dbData:[],
        pineconeData:[]
      }
      data.forEach(item =>{
        const htmlContent = markdownToHtml(item[1])
        const markdownContent = item[1].replace(/(\[linebreak\/\])/g, '\n')
        const description = extractWordsFromHtml(htmlContent)
        const existingArticle = records.find(article=>article.articleData.title.trim()===item[0])
        const articleId = existingArticle? existingArticle.articleData.id: uuidv4()
        result.dbData.push(
          {
            id:articleId,
            title:item[0],
            content:htmlContent,
            description,
            scripted:true,
            status:[{label:'Published', value:'true'}],
            // audience:transformAudienceData(item[2]),
            audience:[],
            restrictAgents:false,
            restrictTeamSpace: false,
            resolution_note:false,
            views:0,
            rating:0
          })
        result.pineconeData.push(
          {
            id:articleId,
            description:markdownContent,
            title:item[0],
            property:item[2]?`${item[2]}|team:${workspaceMetadata}`:`team:${workspaceMetadata}`
          })
        })
        
        return result
    }

    /** --------------------------- Convert properties column in scripted answers file to the format the multiselect expects ---------------- */
    const transformAudienceData = (data) => {
      return data.split('|').map(item=> {
        const kv = item.split(':')
        return {
          key:kv[0],
          values:kv[1].split(',')
        }
      })
    }

    /**---------------------------- Extract words from Article content in html string form --------------------------- */
    const extractWordsFromHtml = (htmlString) => {
      // const textContent = htmlString.replace(/<\/(p|li)>/g, ' ').replace(/<[^>]*>/g, '').trim()
      const textContent = new DOMParser().parseFromString(htmlString, 'text/html').body.textContent
      const words = textContent.split(/[\s\t]+/).slice(0,80)
      // const sentences = textContent.split(/([.!?])/g)
      // const cleanedSentences = sentences
      //     .filter((sentence) => sentence.trim().length > 0)
      //     .map((sentence) => sentence.trim())
      // let combinedWordCount = 0
      // const selectedSentences = []

      // for (let i = 0; i < cleanedSentences.length; i += 2) {
      //     const sentence = cleanedSentences[i]
      //     const punctuation = cleanedSentences[i + 1] || '' 
      //     const words = sentence.split(/[\s\t]+/) 
      
      //     if (combinedWordCount + words.length <= 80) {
      //       selectedSentences.push(sentence + punctuation)  
      //       combinedWordCount += words.length
      //     } else {
      //       break
      //     }
      //   }
      // return selectedSentences.join(' ')
      return words.join(' ')
    }
    /** -----------------------Convert markdown string to html string ---------------------------- */ 
    const markdownToHtml = (markdownString) => {
        let contentStateeditor = marked(markdownString, {renderer: render_content()})
        contentStateeditor = contentStateeditor.replace(/\[linebreak\/\]<img/g, '<img').replace(/\>\[linebreak\/\]/g, '>').replace(/\}\[linebreak\/\]/g, '}')
        contentStateeditor = contentStateeditor.replace(/\\n/g, '\n')
        .replace(/(\[button\]Next\[\/button\]\[pagebreak\/\])/g, '---------------')
        .replace(/(\[button\]Next\[\/button\])/g, '')
        .replace(/\[linebreak\/\]<img/g, '<img').replace(/\>\[linebreak\/\]/g, '>').replace(/\}\[linebreak\/\]/g, '}')
        .replace(/(\[linebreak\/\])/g, '<br>')
        .replace(/<video[^>]*>([\s\S]*?)<\/video>/gi, `![image](${cdnUri}/cdn/thumbnail.png)`)
        .replace(/^(\d+\.)\s*(.*?)$/gm, "<br>$1$2")

        contentStateeditor = replaceAll(contentStateeditor, '<p></p>)', '')
        if (! contentStateeditor.includes('$schema'))  contentStateeditor =  contentStateeditor.replace(/\{.*?\}/g, '')
        else  contentStateeditor =  contentStateeditor.replace(/\\+([\[\]\*_])/g, '$1')

        let imagePixel = []
        let splitImgTag = contentStateeditor.split('"Image">')
        if (splitImgTag.length > 1) {
          splitImgTag.forEach(e => {
            let reg = e.split('}')[0].split('{')[1]
            if (reg) imagePixel.push(`style="${reg.replaceAll('=', ':').split(' ').join(';').concat(';')}"`)
          })
          if (imagePixel.length) {
            contentStateeditor = contentStateeditor
              .split('title="Image">')
              .map((x, idx) => {
                return x.concat(`"Image" ${imagePixel[idx]} >`)
              })
              .join('\n')
              .replace(/\{.*?\}/g, '')
              .replace('"Image" undefined >', '')
          }
        }
        contentStateeditor = replaceAll(contentStateeditor, '"Image" undefined >', '')
        return contentStateeditor
    }
    /** ----------------------- Renderer to be used by marked for converting markdown to html------------------- */
    const render_content = () => {
      let render = new marked.Renderer()
      render.list = function (body, ordered, start) {
        let newtext = body.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
        if (ordered) {
          return `<ol>${newtext}</ol>`
        } else {
          return `<ul>${newtext}</ul>`
        }
      }

      render.blockquote = function (quote) {
        let newtext = quote.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
        return `<blockquote>${newtext}</blockquote>`
      }
      render.code = function (string) {
        return string
      }
      render.image = function (href, title, text, alt, height, width) {
        var newImage = `<img src="${href}" alt="Image" title="Image">`
        return newImage
      }
      return render
    }
    /** ----------------------------Event Handlers --------------------------- */
    /** -------------------------- Handle clicking on Create new article ------------------------- */
    const handleCreateArticle = () => {
      updateListPageProps({
        currentPage,
        itemsPerPage,
        searchQuery,
        filterRecords
      })
      openCreatePage()
    }
    /**  -------------------------------- Handle clicking on Edit article icon -------------------------- */
    const handleEditArticle = (id) => {
      updateListPageProps({
        currentPage,
        itemsPerPage,
        searchQuery,
        filterRecords
      })
      updateCreateEditPageProps('list')
      openEditPage(id)
    }
  
    /**  -------------------------------- Handle clicking on article to view details -------------------------- */
    const handleArticleDetail = (id) => {
      updateListPageProps({
        currentPage,
        itemsPerPage,
        searchQuery,
        filterRecords
      })
      openDetailPage(id)
    }

      
    /**  -------------------------------- Handle clicking on delete article icon -------------------------- */
    const handleArticleDelete = (dbId,articleId,callPinecone) => {
      updateListPageProps({
        currentPage,
        itemsPerPage,
        searchQuery,
        filterRecords
      })
      openDeleteModal(dbId,articleId,callPinecone)
    }
    /** ---------------------- Handle Clicking on Import Scripted Answers button -----------------------*/ 
    const handleFileChange = (e) => {
      setAllowImport(false)
      const file = e.target.files[0]
      const extension = file.name.split('.').pop()
      const readFile = async (file) => {
        if(file){
          if(extension === 'csv'){
            Papa.parse(file, {
              skipEmptyLines:true,
              complete: function(results){
                if(results.errors?.length > 0){
                  toast({
                    title: 'Parsing Error',
                    description: 'There was an error parsing the scripted answer file.' ,
                    status: 'error',
                    duration: 5000,
                    position: 'top-right',
                    isClosable: true,
                  })
                  setAllowImport(true)
                }
                 
                else if(!(results.meta.fields.includes('custom_questions') && results.meta.fields.includes('custom_answers') && results.meta.fields.includes('properties'))){
                  setAllowImport(true)
                  toast({
                    title: 'Format Error',
                    description: 'Uploaded file was not a valid scripted answer file. Please upload a valid scripted answer file.' ,
                    status: 'error',
                    duration: 5000,
                    position: 'top-right',
                    isClosable: true,
                  })
                }
                else {
                  const csvData = results.data.map(item=>[item.custom_questions,item.custom_answers,item.properties])
                  uploadScriptedAnswers(csvData)
                }
              },
              header: true, 
            })
          }else{
             const data = await file.arrayBuffer()
              const workbook = read(data)
              const json_data = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1,blankrows:false })
              if(validateExcelData(json_data)){
                uploadScriptedAnswers(json_data.slice(1))
              }
              else{
                setAllowImport(true)
                toast({
                  title: 'Format Error',
                  description: 'Uploaded file was not a valid scripted answer file. Please upload a valid scripted answer file.' ,
                  status: 'error',
                  duration: 5000,
                  position: 'top-right',
                  isClosable: true,
               })
              }
          } 
        }
      }
      readFile(file)
      importRef.current.value=''
    }
    /** ----------------------------- End Handle Clicking on Import Scripted Answers button --------------  */

    /** ----------On Selecting an option from the Article filter drop-down  ---------- */
    const handleMultiSelectChange = (value) =>{
        setFilterRecords(value)
        setCurrentPage(1)
    }
    
    /**  ------------------------------Pagination---------------------------------- */

    const handleItemsPerPage=(e)=>{
     setItemsPerPage(e.target.value)
     setCurrentPage(1)
    }
    const handlePrev=()=>{
      if(currentPage===1){
        setCurrentPage(1)
      }
      else{
        setCurrentPage(currentPage - 1)
      }
    }
    const handleNext=()=>{
     if(currentPage>pages[pagesLength-1]){
      setCurrentPage(pages[pagesLength-1])
      }
      else{
        setCurrentPage(currentPage+1)
      }
    }
    const handlePageNumber=(e)=>{
    setCurrentPage(e)
    }
  /**  ---------------------------------End Pagination------------------------- */
  /** --------------------------------End Event Handlers -------------------------------- */
  
  
    return (
      <Stack>
        {!allowImport && 
          <Progress position="fixed" top="2" right="4" height='10px' width='50%' zIndex="9999" isIndeterminate/>
        }
        <Flex justify="space-between" padding='10px 1px' alignItems="center">
          <Flex gap='10px' alignItems='center'>
            <Text color='#000' fontWeight={500} fontSize='18px' data-testid='ka_table_heading'>Knowledge Articles</Text>
            <Tooltip placement='right' bg='transparent' color='#000' boxShadow='none'
                label={
                <Flex fontSize='14px' p='10px' gap='10px' alignItems='flex-start' w='331px' bg='#fff' borderRadius='6px' boxShadow='0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)' >
                    <HiOutlineInformationCircle size='24px' color="#E79B04"/>
                    <Text as='span' data-testid='ka_table_heading_tooltip'>Articles restricted to TeamSpaces would not be listed here.</Text> 
                </Flex>
                }
            >
                <span><HiOutlineInformationCircle color='#94A3B8' size='24px'/></span>   
            </Tooltip>
          </Flex>
            <Flex alignItems={'center'} gap='24px'>
              <InputGroup className="search-wrapper" maxW='285px' h='36px' data-testid='ka_table_search'>
                  <Input placeholder="Search" fontSize='14px' color='#617182' value={searchQuery} onChange={(e)=> {
                    setCurrentPage(1)
                    setSearchQuery(e.target.value)
                    }} />
                  <InputRightElement pointerEvents="none" children={<FiSearch />} />
              </InputGroup>
              <Box data-testid={'ka_table_filter'} width='100%'>
                  <MultiSelect 
                      width={'100%'} 
                      name='filterStatus' 
                      isMulti={false}
                      options={[{label:'All Articles',value:'all'},{label:'Draft', value:'draft'}]} 
                      onChange={handleMultiSelectChange}
                      dropdownHandleRenderer={() => (
                        <HiOutlineFunnel/>
                      )}
                      // onChange={handleInputChange} 
                      value={filterRecords} 
                      // placeholder='Select attribute'
                      style={{ height: '40px', padding:'16px 15px 16px 17px', color:'#6B7280', fontSize:'14px', fontWeight:'bolder', borderRadius:'6px', border:'1px solid #D1D5DB',width:'100%' }}
                  />
              </Box>
              <Box maxW='285px' h='36px' data-testid='ka_table_import_answers'>
                  <Input placeholder="Import Scripted Answers" type='file' hidden accept=".csv, .xls, .xlsx" fontSize='14px' ref={importRef} onChange={handleFileChange} />
                  <Button variant='outline' isDisabled={!allowImport || workspaceMetadata==='noWorkSpace'} padding='9px 15px 9px 17px'leftIcon={<FiUpload size='24px' color='#94A3B8'/>} color="#6B7280"  fontSize='14px' onClick={()=>importRef.current.click()}> Import Scripted Answers</Button>
              </Box>
              <Button className='upload-btn' padding='9px 15px 9px 17px' minW='160px' bg="#2563eb" color="#fff" fontSize='14px' onClick={handleCreateArticle} data-testid='ka_table_create_button' isDisabled={workspaceMetadata==='noWorkSpace'}
              title={workspaceMetadata==='noWorkSpace'?'Please select valid workspace':''}> Create New Article</Button>
  
            </Flex>
        </Flex>
          {
            globalState.isFetching ?
              ( 
                <Flex direction="column" justifyContent='center' alignItems='center' >
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='#2ac769'
                        size='xl'
                        data-testid='ka_table_fetching_articles_spinner'
                    />
                    <Text my='2' data-testid='ka_table_fetching_articles_text'>Fetching Articles ...</Text>
                </Flex>
              ):
            records.length===0 ? 
              (
                <Flex flexDir="column" justifyContent='center' alignItems='center' data-testid='ka_table_no_articles_container'>
                    <Image width='140px' height='140px' src={norecord} alt='no records' data-testid='ka_table_no_articles_image'/>
                    <Text as='h5' fontSize='18px' fontWeight='bolder' my='20px' textAlign='center' data-testid='ka_table_no_articles_title' >No articles found!</Text>
                    <Text data-testid='ka_table_no_articles_text'>You can add new Articles by clicking on the Create New Article button on the top right.</Text>
                </Flex>
            
            ):
            totalRecordsToBeDisplayed.length===0 ?
             (
              <Flex flexDir="column" justifyContent='center' alignItems='center' data-testid='ka_table_no_articles_container'>
                    <Image width='140px' height='140px' src={norecord} alt='no records' data-testid='ka_table_no_articles_image'/>
                    <Text as='h5' fontSize='18px' fontWeight='bolder' my='20px' textAlign='center' data-testid='ka_table_no_articles_title' >No articles found!</Text>
                    <Text data-testid='ka_table_no_articles_text'>Please retry with a different search query or filter option.</Text>
                </Flex>
             ):
           currentPageRecords.map((item,index)=>{
            const data = item.articleData
            return(
              <Flex key={index} padding='15px 0px' direction='column' gap='10px' alignItems='flex-start' alignSelf='stretch' borderBottom='1px solid #D1D5DB' data-testid={`article_container_${index}`}>
                <Flex justifyContent='space-between' alignItems='flex-start' alignSelf='stretch'>
                  <Flex gap='5px' alignItems='center'>
                    <Text fontSize='14px' cursor='pointer' fontWeight='bolder' color='#000' onClick={()=>handleArticleDetail(item.id)} data-testid='ka_table_article_title'>{data.title}</Text>
                    {data.scripted &&
                        <Flex gap='4px' alignItems='center' justify='center' padding='2px 5px' borderRadius='100px' border='1px solid #8463F9'>
                            <Text as='span' fontSize="12px" fontWeight='bolder' color='#8463F9' data-testid='ka_table_article_scripted'>Scripted Answer</Text>
                        </Flex>
                    }
                     {data.resolution_note &&
                        <Flex gap='4px' alignItems='center' justify='center' padding='2px 5px' borderRadius='100px' border='1px solid #229F54'>
                            <Text as='span' fontSize="12px" fontWeight='bolder' color='#229F54' data-testid='ka_table_article_resolution'>Resolution Note</Text>
                        </Flex>
                    }
                    {data.restrictAgents &&
                        <Flex gap='4px' alignItems='center' justify='center' padding='2px 5px' borderRadius='100px' border='1px solid #E79B04'>
                            <Text as='span' fontSize="12px" fontWeight='bolder' color='#E79B04' data-testid='ka_table_article_agent'>Agent note</Text>
                        </Flex>
                    }
                  </Flex>
                  <Flex gap='10px'>
                      {(data.views !==null &&  data.views !== undefined && data.views !==0) &&
                        <Tooltip placement='bottom-start' data-testid='ka_table_article_view_tooltip' label={`was referred ${data.views} times in bot conversations`} fontSize='12px' bg='#fff' color='#000' borderRadius='5px' boxShadow='0px 4px 15px 0px rgba(0, 0, 0, 0.10)'>
                            <Flex padding='2px 5px' h='19px' gap='4px' borderRadius='2px' border='1px solid #2563EB' color='#2563EB' alignItems='center'>
                                <MdOutlineRemoveRedEye size='15px' data-testid='ka_table_view_icon'/>
                                <Text as='span' fontSize='12px' fontWeight='bolder' data-testid='ka_table_article_view_data'>{data.views}</Text>
                            </Flex>
                        </Tooltip>
                      }
                      {(data.rating !==null &&  data.rating !== undefined && data.rating !==0) &&
                        <Flex padding='2px 10px' h='19px' gap='5px' borderRadius='2px' border='1px solid #E79B04' color='#E79B04' alignItems='center'>
                            <MdOutlineStarBorder size='12px' data-testid='ka_table_rating_icon'/>
                            <Text as='span' fontSize='12px' fontWeight='bolder' data-testid='ka_table_article_rating_data'>{data.rating}/5</Text>
                        </Flex>     
                      } 
                  </Flex>
                </Flex>
                <Flex gap='13px' direction='column' alignSelf='stretch'>
                  <Box fontSize='14px' data-testid='ka_table_description'>{data.description}</Box>
                  <Flex justify='space-between' h='24px' alignItems='center' alignSelf='stretch'>
                    <Flex gap='10px' align-items='center'>
                         {/* ------------------- Disable for Nov 2023 release -------------------
                          {(data.folder && data.folder!=='') && 
                            <Flex padding='2px 5px' h='19px' gap='4px' alignItems='center' borderRadius='2px' border='1px solid #2563EB'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                    <path d="M1.25 4.56246V11.4375H6.19625L9.63375 7.99996L6.19625 4.56246H1.25ZM2.5 5.81246H5.67875L7.86625 7.99996L5.67875 10.1875H2.5V5.81246ZM9.11625 5.18746L11.9287 7.99996L9.11625 10.8125L10 11.6962L13.6962 7.99996L10 4.30371L9.11625 5.18746Z" fill="#2563EB"/>
                                </svg>
                                <Text as='span' fontSize='12px' fontWeight='bolder' color='#2563EB' data-testid='ka_table_article_folder'>{data.folder}</Text>
                            </Flex>
                          }   */}
                         {data.status.length > 0 && data.status[0].label==='Draft' ?
                          <Text as='span' padding='2px 5px' borderRadius='2px' border='1px solid #E79B04' fontSize='12px' fontWeight='bolder' color='#E79B04'>{data.status[0].label}</Text>
                          : <></>}
                    </Flex>
                    <Flex gap={2}>
                        <Tooltip placement='top' label='Edit Article'><span><FiEdit size='24px' color='#94A3B8' onClick={()=>{workspaceMetadata!=='noWorkSpace' && handleEditArticle(item.id)}} data-testid='ka_table_article_edit_button' cursor='pointer'/></span></Tooltip>
                        <Tooltip placement='top' label='Delete Article'><span><HiOutlineTrash size='24px' color='#94A3B8' onClick={()=>handleArticleDelete(item.id,data.id,((data.status[0].label==='Published' && data?.resolution_note!==true)?true:false))} data-testid='ka_table_article_delete_button' cursor='pointer'/></span></Tooltip>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            )
            })
          }
        <Box display='flex' justifyContent='space-between' width='100%' my='5' >
          <Box display='flex' alignItems='center' >
              Show
              <Box border='1px solid #e2e8f0' borderRadius='5px' mx='3' p='5px 10x' >
              <select onChange={handleItemsPerPage} style={{borderRadius:'5px',height:'30px'}}>
                  <option value='25'>25</option>
                  <option value='50' >50</option>
              </select>
              </Box>
              articles per page
          </Box>
          <Pagination totalPages={pagesLength} currentPage={currentPage} onPageChange={handlePageNumber} />
        </Box>
      </Stack> 
    )
  }

  export default KnowledgeArticlesTable