import { Flex, Box, Text, Button, Spinner } from '@chakra-ui/react'
import { useQuery } from 'react-query'
import * as AdaptiveCards from 'adaptivecards'
import parse from 'html-react-parser'
import marked from 'marked'
import moment from 'moment'
import { HiOutlineArrowCircleLeft } from 'react-icons/hi'
import { FaStar } from 'react-icons/fa6'
import { useServicesContext } from 'services/apiServices'
import { useKnowledgeGapsContext } from '../context/KnowledgeGapsContext'
import ChatDetails from '../../../../explainability_ui/ChatDetails'
import { BiBox } from 'react-icons/bi'
import '../css/style.css'

const ViewChatPage = () => {
  const { closeDrawer,globalState } = useKnowledgeGapsContext()
 
  return (
    <Flex direction='column' gap='20px' p='20px'>
      <Flex gap='4px' alignItems='flex-start' borderBottom='1px solid #D9D9D9' pb='18px'>
        <HiOutlineArrowCircleLeft cursor='pointer' size='24px' color='#4299E1' onClick={closeDrawer} data-testid='kg_drawer_home_back_icon'/>
        <Text as='span' fontSize="16px" fontWeight='bolder'  data-testid='kg_drawer_home_heading'>Review Chat</Text>
      </Flex>
      <ChatDetails chatData={globalState.drawerState.data} />
      <Button alignSelf='flex-end' bg="transparent" padding='0px 28px' height='36px' variant='outline' color="#6B7280"  fontSize='14px' onClick={closeDrawer} data-testid='kg_drawer_home_close_button'>Close</Button>
    </Flex>
  )
}
export default ViewChatPage
