import { Stack, Heading, Text, Divider,  Drawer,DrawerOverlay, DrawerContent, DrawerBody,useToast} from "@chakra-ui/react"
import {useCommonContext} from 'app/context/CommonContext'
import {useKnowledgeArticlesContext} from './context/KnowledgeArticlesContext'
import {useServicesContext } from "services/apiServices"
import KnowledgeArticlesTable from './KnowledgeArticlesTable'
import KnowledgeArticlesDeleteModal from "./KnowledgeArticlesDeleteModal"
import KnowledgeArticlesCreatePage from "./KnowledgeArticlesCreatePage"
import KnowledgeArticlesDetailPage from "./KnowledgeArticlesDetailPage"
import KnowledgeArticlesPreviewModal from "./KnowledgeArticlesPreviewModal"
import ErrorPage from 'app/commonComponents/ErrorPage'
import './articles.css'

const KnowledgeArticlesContainer = () => {
    const {globalState,openListPage,openDetailPage,openPreviewModal,onCreateEditSuccess,onCreateEditfailure} = useKnowledgeArticlesContext()
    const {apiCall,commonAttributes,disabledSources} = useServicesContext()

    const handleBack = () =>{
        if(globalState.createEditPageProps.prevPage==='list')
            openListPage()
        else
            openDetailPage()
    }
    const isEnabled = !disabledSources.includes('Knowledge Articles')
    const pageElement = !isEnabled ?
        <Stack>
            <Heading as="h6" fontSize="18px" fontWeight='500' color='#000'>Knowledge Articles</Heading>
            <Divider />
            <ErrorPage errorTitle='Access Forbidden' errorMessage={`Sorry, you don't have permission to access this page. If you believe this is an error, please contact your customer service manager.`} statusCode={403} buttonText='Go Back' />

        </Stack>
        :globalState.page === 'list' ?
        <KnowledgeArticlesTable />
        : globalState.page ==='create' || globalState.page ==='edit' ?
            <KnowledgeArticlesCreatePage page={globalState.page} articles={globalState.articles} selectedArticle={globalState.selectedArticle} audienceAttributes={commonAttributes}
                handleBack={handleBack} callBackend={apiCall} openPreviewModal={openPreviewModal}
                onSubmitSuccess={onCreateEditSuccess} onSubmitFailure={onCreateEditfailure} parent='knowledge article'

            />
        :globalState.page === 'detail' ? 
            <KnowledgeArticlesDetailPage />
        :   <></>
  return (
        <Stack  borderRadius="10px" pos="relative" color='#617182'>
            {pageElement}
            <KnowledgeArticlesDeleteModal />
            <KnowledgeArticlesPreviewModal />
        </Stack>
  )
}

export default KnowledgeArticlesContainer